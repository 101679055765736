
export class BackendInstance {
  constructor(
    public name: string,
    public url: string
  ) {
  }
}

export function makeBackendInstance(jsonData: any): BackendInstance {
  if (!jsonData) {
    jsonData = {}
  }
  return new BackendInstance(
    jsonData.name || '',
    jsonData.url || ''
  )
}

export class FrontendSetting {
  constructor(
    public field: string,
    public value: string
  ) {
  }
}

export function makeFrontendSetting(jsonData: any): FrontendSetting {
  return new FrontendSetting(
    jsonData.field || '',
    jsonData.value || ''
  )
}

export class FrontendInstance {
  constructor(
    public id: number,
    public url: string,
    public backend: BackendInstance,
    public settings: FrontendSetting[]
  ) {
  }

  public getFrontendSetting(name: string): string {
    for (const setting of this.settings) {
      if (setting.field === name) {
        return setting.value
      }
    }
    return ''
  }
}

export function makeFrontendInstance(jsonData: any): FrontendInstance {
  if (!jsonData) {
    jsonData = {}
  }
  const settings: any[] = jsonData.settings || []
  return new FrontendInstance(
    jsonData.id || 0,
    jsonData.url,
    makeBackendInstance(jsonData.backend || null),
    settings.map((subElt: any) => makeFrontendSetting(subElt))
  )
}

export class User {
  constructor(
    public email: string = '',
    public token: string = '',
    public refresh: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public backend: BackendInstance|null = null
  ) {
  }
}

export function makeUser(jsonData: any, backend: BackendInstance|null = null): User|null {
  if (jsonData.user && jsonData.token) {
    return new User(
      jsonData.user.email,
      jsonData.token,
      jsonData.refresh,
      jsonData.user['first_name'],
      jsonData.user['last_name'],
      backend
    )
  }
  return null
}
