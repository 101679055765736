import {
  Gender, EntityMembership, EntityRole, Entity, makeEntityMembership, Individual, makeEntity
} from '@/types/people'
import { Family } from '@/types/families'
import { School } from '@/types/schools'

export const peopleModule = {
  state: {
    families: [],
    familySearch: '',
    searchedFamilies: [],
    showAllFamilies: false,

    entities: [],
    entitySearch: '',
    searchedEntities: [],
    showAllEntities: false,

    genders: [],
    roles: [],
    schools: [],
    editMode: '',
    searchedIndividuals: [],
    sidebarIndividual: null,
    sidebarEntity: null,
  },
  getters: {
    legalMember: (state: any) => {
      return state.legalMember
    },
    legalRepresentatives: (state: any) => {
      return state.legalRepresentatives
    },

    families: (state: any) => {
      return state.families
    },
    searchedFamilies: (state: any) => {
      return state.searchedFamilies
    },
    familySearch: (state: any) => {
      return state.familySearch
    },
    showAllFamilies: (state: any) => {
      return state.showAllFamilies
    },

    entities: (state: any) => {
      return state.entities
    },
    searchedEntities: (state: any) => {
      return state.searchedEntities
    },
    entitySearch: (state: any) => {
      return state.entitySearch
    },
    showAllEntities: (state: any) => {
      return state.showAllEntities
    },

    family: (state: any) => {
      return state.family
    },
    entity: (state: any) => {
      return state.entity
    },
    member: (state: any) => {
      return state.member
    },
    isChild: (state: any) => {
      return state.member.role.isChild
    },
    memberData: (state: any) => {
      return state.memberData
    },
    memberEdition: (state: any) => {
      return state.memberData !== null
    },
    genders: (state: any) => {
      return state.genders
    },
    familyEdition: (state: any) => {
      return state.familyEdition
    },
    roles: (state: any) => {
      return state.roles
    },
    schools: (state: any) => {
      return state.schools
    },
    members: (state: any) => {
      if (state.entity) {
        return state.entity.memberships
      }
      return []
    },
    editMode: (state: any) => {
      return state.editMode
    },
    searchedIndividuals: (state: any) => {
      return state.searchedIndividuals
    },
    sidebarIndividual: (state: any) => {
      return state.sidebarIndividual
    },
    sidebarEntity: (state: any) => {
      return state.sidebarEntity
    },
  },
  mutations: {
    setSearchedIndividuals(state: any, individuals: Individual[]) {
      state.searchedIndividuals = individuals
    },
    setFamilies(state: any, families: Family[]) {
      state.families = families
    },
    setSearchedFamilies(state: any, families: Family[]) {
      state.searchedFamilies = families
    },
    setSearchedEntities(state: any, entities: Entity[]) {
      state.searchedEntities = entities
    },
    setEntitySearch(state: any, text: string) {
      state.entitySearch = text
    },
    setEntities(state: any, entities: Entity[]) {
      state.entities = entities
    },

    setEditMode(state: any, editMode: string) {
      state.editMode = editMode
    },

    setGenders(state: any, genders: Gender[]) {
      state.genders = genders
    },

    setFamilySearch(state: any, text: string) {
      state.familySearch = text
    },

    setEntityRoles(state: any, roles: EntityRole[]) {
      state.roles = roles
    },
    setSchools(state: any, schools: School[]) {
      state.schools = schools
    },

    setLegalMember(state: any, member: EntityMembership) {
      state.legalMember = member
    },
    resetLegalMember(state: any) {
      state.legalMember = null
    },

    setEntityUpdated(state: any) {
      if (state.entity) {
        state.entity.updatedOn = new Date()
      }
    },

    setShowAllFamilies(state: any, value: boolean) {
      state.showAllFamilies = value
    },
    setShowAllEntities(state: any, value: boolean) {
      state.showAllEntities = value
    },

    setSidebarIndividual(state: any, individual: Individual|null) {
      state.sidebarIndividual = individual
    },

    setSidebarEntity(state: any, entity: Entity|null) {
      state.sidebarEntity = entity
    },
  },
  actions: {
  },
  modules: {
  },
}

export default peopleModule
