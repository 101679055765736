import { PublicClientApplication } from '@azure/msal-browser'
import axios from 'axios'
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { loadConfig } from '@/apis/config'
import { loadPermissions } from '@/apis/permissions'
import store from '@/store'
import { User, makeUser, BackendInstance, makeBackendInstance } from '@/types/auth'
import { gerRouterUrl, getErrorText } from '@/utils/http'
import { Individual, Entity } from '@/types/people'

export const BackendMixin = Vue.extend({
  created: () => {},
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),

    isLoading(loadingName: string): boolean {
      return store.getters.isLoading(loadingName)
    },

    hasPerm(permName: string): boolean {
      return store.getters.hasPerm(permName)
    },

    hasAllPerms(perms: string[]): boolean {
      return store.getters.hasAllPerms(perms)
    },

    hasOnePerm(perms: string[]): boolean {
      return store.getters.hasOnePerm(perms)
    },

    getFullBackendUrl(url: string): string {
      return store.getters.backendUrl + url
    },

    getFrontendSetting(setting: string): string {
      if (store.getters.frontendInstance) {
        return store.getters.frontendInstance.getFrontendSetting(setting)
      }
      return ''
    },

    async registerUser(data: any, backend: BackendInstance): Promise<User> {
      const that = this
      return new Promise(
        async function(resolve, reject) {
          const user = makeUser(data, backend)
          await store.dispatch('setUser', user)
          await loadConfig()
          await loadPermissions()
          if (user) {
            resolve(user)
          } else {
            reject(Error('Utilisateur inconnu'))
          }
        }
      )
    },

    login(backend: BackendInstance, email: string, password: string): Promise<User> {
      return new Promise(
        (resolve, reject) => {
          let fullUrl: string = backend.url + '/users/api/login/'
          axios({
            url: fullUrl,
            data: { email, password, app: 'staff', },
            method: 'POST',
          }).then(async(resp: any) => {
            if (!resp.data.success) {
              reject(Error(resp.data.message))
            } else {
              const user = await this.registerUser(resp.data, backend)
              if (user) {
                resolve(user)
              } else {
                reject(Error('Utilisateur inconnu'))
              }
            }
          }).catch(err => {
            reject(err)
          })
        }
      )
    },

    getAzureClient() : any {
      const azureClientId = this.getFrontendSetting('azure_ad_client_id')
      const azureTenantId = this.getFrontendSetting('azure_ad_tenant_id')
      if (azureClientId) {
        const config: any = {
          auth: {
            clientId: azureClientId,
            redirectUri: window.location.origin + '/azure-login',
          },
        }
        if (azureTenantId) {
          config.auth.authority = 'https://login.microsoftonline.com/' + azureTenantId
        }
        return new PublicClientApplication(config)
      }
      return null
    },

    loginSSO(backend: BackendInstance, url: string, credentials: any): Promise<User> {
      return new Promise(
        (resolve, reject) => {
          let fullUrl: string = backend.url + url
          axios({
            url: fullUrl,
            data: credentials,
            method: 'POST',
          }).then(async(resp: any) => {
            if (!resp.data.success) {
              reject(Error(resp.data.message))
            } else {
              const user = await this.registerUser(resp.data, backend)
              if (user) {
                resolve(user)
              } else {
                reject(Error('Utilisateur inconnu'))
              }
            }
          }).catch(err => {
            reject(err)
          })
        }
      )
    },

    azureAutoConnect(): boolean {
      return (
        (this.getFrontendSetting('azure_ad_client_id') !== '') &&
        (this.getFrontendSetting('azure_auto_connect') === '1')
      )
    },

    logout(): Promise<boolean> {
      return new Promise(
        async(resolve) => {
          await store.dispatch('resetUser')
          if (this.azureAutoConnect()) {
            const azureClient = this.getAzureClient()
            if (azureClient) {
              await azureClient.logout()
            }
          }
          resolve(true)
        }
      )
    },

    getBackendsFromEmail(email: string): Promise<BackendInstance[]> {
      return new Promise(
        (resolve, reject) => {
          axios({
            url: gerRouterUrl('/api/backends/accounts/'),
            data: { email, },
            method: 'POST',
          }).then(async(resp: any) => {
            if (!resp.data.success) {
              reject(Error(resp.data.message))
            } else {
              const backends: BackendInstance[] = resp.data.backends.map(
                (jsonElt: any) => makeBackendInstance(jsonElt)
              ).filter(
                (elt: BackendInstance) => elt.url
              )
              resolve(backends)
            }
          }).catch(err => {
            reject(err)
          })
        }
      )
    },

    getErrorText(err: any): string {
      return getErrorText(err)
    },

    getAdminLink() {
      return this.getFullBackendUrl('/www-admin/')
    },

    showIndividualSidebar(individual: Individual) {
      if (store.getters.sidebarEntity) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-entity')
      }
      if (store.getters.sidebarIndividual && (individual.id !== store.getters.sidebarIndividual.id)) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-individual')
      }
      store.commit('setSidebarIndividual', individual)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-individual')
    },

    showEntitySidebar(entity: Entity) {
      if (store.getters.sidebarIndividual) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-individual')
      }
      if (store.getters.sidebarEntity && (entity.id !== store.getters.sidebarEntity.id)) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-entity')
      }
      store.commit('setSidebarEntity', entity)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-entity')
    },
  },
})
